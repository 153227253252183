import { useNavigate } from 'react-router-dom';
import { Container, Typography, Grid, Box, useMediaQuery } from '@mui/material';
import { Trackable } from '@dpdgroupuk/react-event-tracker';
import { REDUCE_REUSE_RECYCLE } from '../../../data/analytics';
import * as routes from '../../../router/routes';
import { REDUCE_REUSE_RECYCLE_VIDEO } from '../../../data/links';

import reduceReuseRecycleBackground from '../../../images/OurVision/reduceReuseRecycleBackground.png';
import recycleImage from '../../../images/OurVision/recycleImage.png';
import plasticWasteBackground from '../../../images/OurVision/plasticWasteBackground.png';
import recyclePackaging from '../../../images/OurVision/recyclePackaging.png';
import recyclePackagingBackground from '../../../images/OurVision/recyclePackagingBackground.png';

import PageHeader from '../../../components/pageHeader/PageHeader.jsx';
import SideBySide from '../../../components/sideBySide/SideBySide.jsx';
import theme from '../../../theme';
import BoxOnBackground from '../../../components/boxOnBackground/BoxOnBackground.jsx';
import ActionButton from '../../../components/actionButton/ActionButton.jsx';
import usePageWidth from '../../../utils/usePageWidth';
import usePadding from '../../../utils/usePadding';

const ReduceReuseRecycle = () => {
  const navigate = useNavigate();

  const handleFindMore = () => {
    navigate(routes.WASTE_RECYCLING);
    window.scrollTo(0, 0);
  };

  const savingsCards = [
    <>
      <Typography variant="h6">We</Typography>
      <Typography variant="h6">recycled over</Typography>
      <Typography variant="h3Light">402 tonnes</Typography>
      <Typography variant="h6">of shrink wrap in 2023</Typography>
    </>,
    <>
      <Typography variant="h6">In 2023</Typography>
      <Typography variant="h6">we repaired and returned</Typography>
      <Typography variant="h3Light">1,100,000 pallets</Typography>
      <Typography variant="h6">ready for reuse</Typography>
    </>,
  ];

  return (
    <Trackable
      interfaceId={REDUCE_REUSE_RECYCLE.INTERFACE_ID}
      loadId={REDUCE_REUSE_RECYCLE.ON_LOAD}
    >
      <PageHeader image={reduceReuseRecycleBackground}>
        <Typography variant="h5" gutterBottom color="#FFF">
          Our Vision
        </Typography>
        <Typography variant="h1" color="#FFF">
          Reduce, Reuse, Recycle
        </Typography>
      </PageHeader>
      <Container
        disableGutters
        sx={{
          minWidth: '100%',
          py: useMediaQuery(theme.breakpoints.down('md')) ? 5 : 30,
          backgroundColor: theme.palette.secondary.background,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ width: usePageWidth(), px: usePadding() }}>
          <Typography
            variant="h2Light"
            gutterBottom
            color={theme.palette.primary.green}
            textAlign="center"
          >
            We hate waste and make sure what little we have is disposed of
            responsibly, which is why 100% of our waste avoids landfill.
          </Typography>
          <Grid container spacing={2}>
            {savingsCards.map((card, index) => (
              <Grid item sm={6} key={index} sx={{ width: '100%' }}>
                <Box
                  sx={{
                    backgroundColor: theme.palette.primary.citySelectorGreenOne,
                    borderRadius: 1,
                    height: '100%',
                    px: '30px',
                    py: '55px',
                    color: '#FFF',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  <Box>{card}</Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
      <SideBySide
        background={theme.palette.primary.background}
        image={recycleImage}
        imageSide="right"
        verticalPadding={20}
        mobileDirection="column-reverse"
      >
        <Box
          sx={
            useMediaQuery(theme.breakpoints.down('md')) && {
              textAlign: 'center',
              paddingTop: 5,
            }
          }
        >
          <Typography variant="h4Light" gutterBottom color="#000">
            For the past 3 years we have achieved a reuse and recycling rate of
            90% whilst maintaining our zero waste to landfill promise.
          </Typography>
          {/* <Typography
            variant={
              useMediaQuery(theme.breakpoints.down('md'))
                ? 'h2Light'
                : 'h1Light'
            }
            gutterBottom
            sx={{ color: theme.palette.primary.green }}
          >
            To achieve our goal of 90% of our waste being reused or recycled we
            have split our waste into 20 key streams to best prepare it for
            processing.
          </Typography> */}
          <Typography variant="body1" gutterBottom color="#000">
            To achieve our goal of 90% of our waste being reused or recycled we
            have split our waste into 20 key streams to best prepare it for
            processing.
          </Typography>
        </Box>
      </SideBySide>
      <Container
        disableGutters
        sx={{
          minWidth: '100%',
          backgroundImage: `url(${plasticWasteBackground})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          pt: useMediaQuery(theme.breakpoints.down('md')) ? 5 : 35,
          pb: useMediaQuery(theme.breakpoints.down('md')) ? 30 : 55,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ width: usePageWidth(), px: usePadding() }}>
          <Typography
            variant={
              useMediaQuery(theme.breakpoints.down('md'))
                ? 'h2Light'
                : 'h1Light'
            }
            gutterBottom
            color={theme.palette.primary.green}
            sx={{ textAlign: 'center' }}
          >
            There are 141 million tonnes of plastic packaging produced globally
            around the world annually.
          </Typography>
          <Typography
            variant="h5Light"
            color="#000"
            sx={{ textAlign: 'center' }}
          >
            It&apos;s important we all work to keep this out of landfill and our
            oceans.
          </Typography>
        </Box>
      </Container>
      <SideBySide
        image={recyclePackaging}
        imageSide="right"
        background={theme.palette.secondary.backgroundLavendar}
        verticalPadding={25}
      >
        <Typography
          variant="h2Light"
          gutterBottom
          color={theme.palette.primary.green}
        >
          We provide plastic packages to protect our customers&apos; goods and
          we want to do something about this.
        </Typography>
        <Typography variant="h3Light" gutterBottom color="#000">
          This is why we&apos;re working with our customers to reduce the number
          of bags used.
        </Typography>
        <Typography variant="body1" gutterBottom color="#000">
          We have also made a major change to the bags themselves.
        </Typography>
      </SideBySide>
      <BoxOnBackground
        background={recyclePackagingBackground}
        boxSide="right"
        verticalPadding={25}
        boxColor={theme.palette.primary.citySelectorGreenOne}
      >
        <Typography
          variant={
            useMediaQuery(theme.breakpoints.down('md')) ? 'h2Light' : 'h1Light'
          }
          gutterBottom
          color="#FFF"
        >
          Here are the details…
        </Typography>
        <Typography variant="h3Light" gutterBottom color="#FFF">
          ...our packaging is made from 80% reclaimed material from the
          agricultural industry that would otherwise make its way to landfill.
        </Typography>
        <Typography variant="h5Light" gutterBottom color="#FFF">
          This packaging is also 100% recyclable*.
        </Typography>
        <Typography variant="annotation" gutterBottom color="#FFF">
          *Check local recycling or recycle with bags at large supermarkets
        </Typography>
      </BoxOnBackground>
      <Container
        disableGutters
        sx={{
          minWidth: '100%',
          backgroundColor: theme.palette.secondary.infoGreen,
          py: 20,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ width: usePageWidth(), px: usePadding() }}>
          <Typography
            variant="h3Light"
            gutterBottom
            sx={{ textAlign: 'center' }}
          >
            Reduce, Reuse, Recycle
          </Typography>
          <iframe
            title={REDUCE_REUSE_RECYCLE_VIDEO}
            src={REDUCE_REUSE_RECYCLE_VIDEO}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            height={
              useMediaQuery(theme.breakpoints.down('md')) ? '200px' : '550px'
            }
            width="100%"
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ActionButton
              text="Find Out More"
              onclick={() => handleFindMore()}
              fullWidth={useMediaQuery(theme.breakpoints.down('md'))}
            />
          </Box>
        </Box>
      </Container>
    </Trackable>
  );
};

export default ReduceReuseRecycle;
