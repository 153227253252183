import {
  Container,
  Box,
  Grid,
  Typography,
  Select,
  MenuItem,
  useMediaQuery,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useTracker } from '@dpdgroupuk/react-event-tracker';
import { VISION_THIRTY } from '../../data/analytics';
import { getCities } from '../../apis/green';

import visionThirtySelectorBackground from '../../images/OurInnovation/visionThirtySelectorBackground.png';
import citySelectorTick from '../../images/OurInnovation/citySelectorTick.png';
import theme from '../../theme';
import usePageWidth from '../../utils/usePageWidth';
import usePadding from '../../utils/usePadding';

const VisionThirtySelector = () => {
  const tracker = useTracker();

  const [cities, setCities] = useState([
    {
      city: 'London',
      population: '8,982,000',
      parcels_per_day: '748,416',
      electric_vehicles: '806',
      status: '2023',
    },
  ]);

  const getGreenCities = async () => {
    const newCities = await getCities();
    if (newCities) {
      setCities(newCities);
    }
  };

  useEffect(() => {
    getGreenCities();
  }, []);

  const [selectedCity, setSelectedCity] = useState(cities[0].city);
  const [cityStats, setCityStats] = useState(cities[0]);

  const handleChangeCity = event => {
    setSelectedCity(event.target.value);
    const currentStats = cities.filter(
      city => city.city === event.target.value
    )[0];
    setCityStats(currentStats);
  };

  return (
    <Container
      disableGutters
      sx={{
        backgroundImage: `url(${visionThirtySelectorBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        minWidth: '100%',
        py: useMediaQuery(theme.breakpoints.down('md')) ? 5 : 25,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          width: usePageWidth(),
          px: usePadding(),
        }}
      >
        <Grid item xs={12}>
          <Box
            sx={{
              display: useMediaQuery(theme.breakpoints.down('md'))
                ? ''
                : 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: theme.palette.primary.citySelectorGreenOne,
              p: 3,
              borderRadius: 1,
            }}
          >
            <Typography
              variant="h5"
              color="#FFF"
              sx={{
                pr: 2,
                whiteSpace:
                  !useMediaQuery(theme.breakpoints.down('md')) && 'nowrap',
                pb: useMediaQuery(theme.breakpoints.down('md')) && 2,
              }}
            >
              View our Electric City:
            </Typography>
            <Select
              fullWidth
              onOpen={() => tracker.logEvent(VISION_THIRTY.CLICK_DROPDOWN)}
              size="small"
              value={selectedCity}
              onChange={event => {
                handleChangeCity(event);
                tracker.logEvent(VISION_THIRTY.CLICK_CITY);
              }}
              sx={{
                backgroundColor: '#FFF',
                color: '#639B40',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: { mt: 2, maxHeight: 200, scrollbarColor: '#639B40' },
                },
              }}
              renderValue={value => <>{value}</>}
            >
              {cities.map(city => (
                <MenuItem
                  key={city.city}
                  value={city.city}
                  sx={
                    selectedCity === city.city
                      ? {
                          color: theme.palette.primary.green,
                          backgroundColor:
                            theme.palette.secondary.innovationInfoGreen +
                            ' !important',
                        }
                      : {
                          color: '#000',
                          opacity: 0.38,
                        }
                  }
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      minWidth: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    {city.city}
                    {selectedCity === city.city && (
                      <Box component="img" src={citySelectorTick} />
                    )}
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Grid>
        <Grid item md={5} xs={12}>
          <Box
            sx={{
              backgroundColor: theme.palette.primary.citySelectorGreenThree,
              borderRadius: 1,
              pt: 5,
              pl: 4,
              pr: 2,
              minHeight: '100%',
            }}
          >
            <Typography variant="h6" color="#FFF" paddingBottom={2}>
              City
            </Typography>
            <Typography
              variant={
                useMediaQuery(theme.breakpoints.down('sm'))
                  ? 'h4Light'
                  : 'h2Light'
              }
              color="#FFF"
              gutterBottom
              sx={{
                wordWrap: 'break-word',
                textDecoration: 'underline',
                textDecorationThickness: 1,
                textUnderlineOffset: 24,
                lineHeight: 2,
                pb: useMediaQuery(theme.breakpoints.down('sm')) ? 4 : 2,
              }}
            >
              {selectedCity}
            </Typography>
          </Box>
        </Grid>
        <Grid item md={7} xs={12}>
          <Box
            sx={{
              backgroundColor: '#FFF',
              borderRadius: 1,
              p: 4,
            }}
          >
            <Grid container spacing={7} alignItems="flex-end">
              <Grid item md={6} xs={12}>
                <Typography
                  variant="h6"
                  color={theme.palette.primary.accentGreen}
                  gutterBottom
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  Population
                </Typography>
                <Typography
                  variant="h2Light"
                  color={theme.palette.primary.green}
                  sx={{
                    textDecoration: 'underline',
                    textDecorationColor: theme.palette.primary.accentGreen,
                    textDecorationThickness: 1,
                    textUnderlineOffset: 24,
                  }}
                >
                  {cityStats.population.toLocaleString()}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography
                  variant="h6"
                  color={theme.palette.primary.accentGreen}
                  gutterBottom
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  Parcels Per Day (avg)
                </Typography>
                <Typography
                  variant="h2Light"
                  color={theme.palette.primary.green}
                  sx={{
                    textDecoration: 'underline',
                    textDecorationColor: theme.palette.primary.accentGreen,
                    textDecorationThickness: 1,
                    textUnderlineOffset: 24,
                  }}
                >
                  {cityStats.parcels_per_day.toLocaleString()}
                </Typography>
              </Grid>
              <Grid item md={3} xs={12}>
                <Typography
                  variant="h6"
                  color={theme.palette.primary.accentGreen}
                  gutterBottom
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  Number of EVs
                </Typography>
                <Typography
                  variant="h2Light"
                  color={theme.palette.primary.green}
                  sx={{
                    textDecoration: 'underline',
                    textDecorationColor: theme.palette.primary.accentGreen,
                    textDecorationThickness: 1,
                    textUnderlineOffset: 24,
                  }}
                >
                  {cityStats.electric_vehicles.toLocaleString()}
                </Typography>
              </Grid>
              <Grid item md={3} xs={12}>
                <Typography
                  variant="h6"
                  color={theme.palette.primary.accentGreen}
                  gutterBottom
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  Completion
                </Typography>
                <Typography
                  variant="h2Light"
                  color={theme.palette.primary.green}
                  sx={{
                    textDecoration: 'underline',
                    textDecorationColor: theme.palette.primary.accentGreen,
                    textDecorationThickness: 1,
                    textUnderlineOffset: 24,
                  }}
                >
                  {cityStats.status}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default VisionThirtySelector;
